import { mapGetters } from 'vuex'

export default {
  created: function() {
    if (this.roles.length === 0) {
      this.$Loading.start()
      this.$store.dispatch('role/getAllRoles').finally(() => {
        this.$Loading.finish()
      })
    }
  },
  computed: {
    ...mapGetters({
      roles: 'role/roles'
    })
  }
}

<template>
  <el-dialog
    :visible="visible"
    :title="isAdd ? '新增管理员' : '修改管理员'"
    width="484"
    class="yt-dialog yt-dialog-default"
    @close="close"
    :close-on-click-modal="false"
  >
    <el-form ref="administratorForm" :model="administratorForm" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="员工" prop="userId" v-if="isAdd">
        <el-select
          ref="addSelect"
          v-model="administratorForm.userId"
          placeholder="请选择员工"
          filterable
          remote
          clearable
          @change="selectByMaster"
          :remote-method="debounceSearchStaffs"
          @on-query-change="queryChange"
          :loading="searchLoading"
        >
          <el-option disabled :value="1000" :key="1000">
            当前关键词: {{ currentRealName }}<br /><br />
            姓名&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;手机号&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;邮箱
          </el-option>
          <el-option v-for="(staff, index) in staffs" :value="index" :key="index" :label="staff.realName">
            <span style="white-space: nowrap;margin-left: 30px"
              >{{ staff.realName }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ staff.phone }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                staff.email
              }}</span
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色" prop="roleIds">
        <el-select v-model="administratorForm.roleIds" multiple clearable placeholder="请选择角色">
          <el-option v-for="role in roles" :value="role.id" :key="'role' + role.id" :label="role.name">{{ role.name }}</el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-Button @click="close" class="button">取消</el-Button>
      <el-Button type="primary" class="button" @click="dealAdministrator('administratorForm')" :loading="loading">确定</el-Button>
    </div>
  </el-dialog>
</template>
<script>
import roleMixin from '@components/mixins/roleMixin'
import departmentMixin from '@components/mixins/departmentMixin'
import staffApi from '@api/staff'
import administratorApi from '@api/administrator'
import debounce from 'lodash/debounce'

const getInitForm = (isAdd, administrator) => {
  return {
    userId: isAdd ? '' : administrator.userId,
    roleIds: isAdd || !administrator.roles ? null : administrator.roles.map(role => role.roleId),
    managedDepartmentId: isAdd || !administrator.departmentId ? null : administrator.departmentId
  }
}

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    administrator: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [roleMixin, departmentMixin],
  data: function() {
    return {
      visible: this.value,
      loading: false,
      staffs: [],
      searchLoading: false,
      administratorForm: getInitForm(this.isAdd, this.administrator),
      ruleValidate: {
        userId: {
          required: true,
          message: '员工不能为空'
        },
        roleIds: {
          required: true,
          message: '角色不能为空'
        },
        managedDepartmentId: {
          required: true,
          message: '管理部门不能为空'
        }
      },
      selectMaster: null,
      currentRealName: ''
    }
  },
  created() {
    this.debounceSearchStaffs = debounce(this.searchStaffs, 200)
  },
  methods: {
    queryChange(query) {
      if (query === '') {
        this.setQuery()
      } else {
        this.searchStaffs(query)
      }
    },
    setQuery() {
      this.currentRealName = ''
      this.staffs = []
    },
    selectByMaster(value) {
      this.selectMaster = this.staffs[value]
    },
    close() {
      this.visible = false
      this.$emit('input', false)
      this.$emit('on-cancel')
    },
    dealAdministrator(name) {
      this.$refs[name].validate(valid => {
        if (!valid) {
          return
        }

        this.loading = true
        let administratorInfo = {}

        if (this.isAdd) {
          administratorInfo = Object.assign(administratorInfo, this.administratorForm)
        } else {
          //修改需要多一项 administratorId
          administratorInfo.administratorId = this.administrator.userId
          administratorInfo.managedDepartmentId = this.administratorForm.managedDepartmentId
          administratorInfo.addRoleIds = this.administratorForm.roleIds.filter(newRoleId => {
            //表单中有的，但是 prop 中没有，为添加
            return this.administrator.roles.every(oldRole => oldRole.roleId !== newRoleId)
          })
          administratorInfo.deleteRoleIds = this.administrator.roles
            .filter(oldRole => {
              //表单中没有，但是 prop 中有，为删除
              return this.administratorForm.roleIds.every(newRoleId => oldRole.roleId !== newRoleId)
            })
            .map(role => role.roleId)
        }
        let payload = {}
        this.isAdd
          ? (payload = {
              managedDepartmentId: this.selectMaster.departmentId,
              roleIds: this.administratorForm.roleIds,
              userId: this.selectMaster.userId
            })
          : ''
        let promise = this.isAdd ? administratorApi.addMaster(payload) : administratorApi.modifyAdministrator(administratorInfo)

        promise
          .then(res => {
            this.close()
            this.isAdd && this.$refs[name].resetFields()
            this.$emit('on-success', res)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    onSelect(current) {
      //模拟 Option 点击，只能暂时这么处理了
      this.$refs.departmentTree.dispatch('iSelect', 'on-select-selected', {
        value: current[0].detail.departmentId,
        label: current[0].detail.name
      })

      this.administratorForm.managedDepartmentId = current[0].detail.departmentId
    },
    searchStaffs(realName) {
      this.searchLoading = true
      this.currentRealName = realName
      let searchStaffDTO = {
        pageParamForm: {
          pageSize: 200
        },
        sortCriteria: {},
        userCriteria: {
          realName: realName
        }
      }
      //最多只显示 200 项
      staffApi
        .searchStaffs(searchStaffDTO)
        .then(res => {
          this.staffs = res.res.data
        })
        .finally(() => {
          this.searchLoading = false
        })
    }
  },
  watch: {
    value(val) {
      this.visible = val
    },
    administrator: function(newVal, oldVal) {
      this.administratorForm = getInitForm(this.isAdd, newVal)
    }
  }
}
</script>
<style lang="less" scoped>
.role-modal ::v-deep .ivu-modal {
  .ivu-modal-body {
    .ivu-select-single .ivu-select-input {
      font-size: 14px;
    }
  }
}
::v-deep .ivu-modal .ivu-select-dropdown .ivu-select-item:hover {
  color: #c5c8ce;
}
::v-deep .ivu-select-item-disabled:hover {
  cursor: initial;
}
</style>
